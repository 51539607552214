import React from "react"

import Layout from "../components/Layout"
import Page from "../components/Layout/page"

const AuctionInfo = (props) => (
  <Layout location={props.location}>
    <Page id="4" />
  </Layout>
)

export default AuctionInfo
